import React, { useState, Fragment } from "react"
import Markdown from "markdown-to-jsx"
import { Text } from "@fastly/consistently"

import DecoratorData from "~data/header_easter_eggs.yml"

const InclusionAndDiversity = (props) => {
  if(typeof window === "undefined") {
    return null
  }

  const today = getCurrentDate()

  let hatched = []
  DecoratorData.forEach((egg) => {
    if (!(egg.start_date <= today && egg.end_date >= today) || egg.iandd == "no" || !egg.description) {
      return
    }
    hatched.push(egg)
  })

  if (!hatched.length) return null

  return hatched.map((egg) => {
    let egg_content = egg.icon.includes(".") ? (
      <img
        src={`/assets/images/decorators/${egg.icon}`}
        alt={egg.alt}
        style={{ width: "40px", height: "40px" }}
        rel="nofollow"
      />
    ) : (
      <Text variant="xl" alt={egg.alt}>
        {egg.icon}
      </Text>
    )

    // append the time for the correct date; see https://css-tricks.com/everything-you-need-to-know-about-date-in-javascript/
    egg.start_date += "T00:00"
    egg.end_date += "T00:00"
    let start_date = new Date(egg.start_date).toLocaleDateString("en-us", { month: "long", day: "numeric" })
    let end_date = new Date(egg.end_date).toLocaleDateString("en-us", { month: "long", day: "numeric" })

    return (
      <Fragment key={`highlight-${egg.name}`}>
        <h2>
          Current highlight: {egg_content} {egg.name} ({start_date} - {end_date})
        </h2>
        <p>
          <Markdown>{egg.description}</Markdown>
        </p>
      </Fragment>
    )
  })
}

function getCurrentDate(separator = "-") {
  const newDate = new Date()
  const year = newDate.getFullYear()
  let month = newDate.getMonth() + 1
  month = month.toString().padStart(2, "0")
  let date = newDate.getDate()
  date = date.toString().padStart(2, "0")

  return [year, month, date].join(separator).toString()
}

export default InclusionAndDiversity
